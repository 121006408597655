exports.components = {
  "component---src-components-portfolio-project-jsx": () => import("./../../../src/components/portfolio/project.jsx" /* webpackChunkName: "component---src-components-portfolio-project-jsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-aws-summit-amsterdam-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/arbor/code/devscoops.com/blog/aws-summit-amsterdam/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-aws-summit-amsterdam-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-comparing-methods-for-storing-theme-in-web-applications-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/arbor/code/devscoops.com/blog/comparing-methods-for-storing-theme-in-web-applications/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-comparing-methods-for-storing-theme-in-web-applications-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-everything-a-web-developer-should-know-about-figma-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/arbor/code/devscoops.com/blog/everything-a-web-developer-should-know-about-figma/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-everything-a-web-developer-should-know-about-figma-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-harnessing-the-power-of-usecontext-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/arbor/code/devscoops.com/blog/harnessing-the-power-of-usecontext/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-harnessing-the-power-of-usecontext-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-context-api-or-redux-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/arbor/code/devscoops.com/blog/using-context-api-or-redux/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-context-api-or-redux-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-why-its-time-to-ditch-redux-in-react-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/arbor/code/devscoops.com/blog/why-its-time-to-ditch-redux-in-react/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-why-its-time-to-ditch-redux-in-react-index-mdx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-index-jsx": () => import("./../../../src/pages/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-index-jsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-test-0932548778-ladfs-jsx": () => import("./../../../src/pages/test0932548778ladfs.jsx" /* webpackChunkName: "component---src-pages-test-0932548778-ladfs-jsx" */),
  "component---src-pages-test-index-jsx": () => import("./../../../src/pages/test/index.jsx" /* webpackChunkName: "component---src-pages-test-index-jsx" */)
}

